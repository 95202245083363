<template>
  <div class="case_shows">
    <div ref="box_title1" class="box_title">
      <h6>案例展示</h6>
    </div>
    <div class="tabs">
      <li @click="handleIndex(1)">
        <span>美素佳儿</span>
        <i v-show="tabsIndex == 1" class="gang"></i>
      </li>
      <li @click="handleIndex(2)">
        <span>大宠爱</span>
        <i v-show="tabsIndex == 2" class="gang"></i>
      </li>
      <li @click="handleIndex(3)">
        <span>GODIVA</span>
        <i v-show="tabsIndex == 3" class="gang"></i>
      </li>
      <!-- <li @click="handleIndex(4)">
        <span>ffit8</span>
        <i v-show="tabsIndex == 4" class="gang"></i>
      </li>
      <li @click="handleIndex(5)">
        <span>Age 20’s</span>
        <i v-show="tabsIndex == 5" class="gang"></i>
      </li>
      <li @click="handleIndex(6)">
        <span>bebe</span>
        <i v-show="tabsIndex == 6" class="gang"></i>
      </li> -->
    </div>
    <div v-for="(item, index) in caseShows" :key="index">
      <div ref="body" class="body body_ini" v-if="tabsIndex == index + 1">
        <div class="top">
          <img
            @click="tableIndex(true)"
            class="jt1"
            src="https://prod.oss.netopstec.com/net-caseShows-jt1.png"
            alt=""
          />
          <img class="head" :src="item.img1" alt="" />
          <img
            @click="tableIndex(false)"
            class="jt2"
            src="https://prod.oss.netopstec.com/net-caseShows-jt2.png"
            alt=""
          />
        </div>
        <div class="main">
          <div class="left">
            <img :src="item.img2" alt="" />
            <div class="text">
              <p>案例亮点</p>
              <span>
                {{ item.body }}
                <br />
                {{ item.bodys }}
              </span>
              <a @click="showText(index)">查看更多</a>
            </div>
          </div>
          <div class="right">
            <div class="text">
              <p>{{ item.title }}</p>
              <li v-for="(items, index) in item.top" :key="index">
                <span></span> {{ items }}
              </li>
            </div>
            <img :src="item.img3" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer" v-if="!IsPc">
        <p>版权所有:杭州网营科技股份有限公司</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block;
          "/></a> 浙公网安备33018302001367号
            <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
        </p>
    </div>
    <div class="footer" v-else>
        <p>版权所有:杭州网营科技股份有限公司</p>
        <p>
            Copyright@2015 Netopstec.All Rights Reserved

        </p>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo"><img src="https://prod.oss.netopstec.com/gongan.png" alt="" style="display: inline-block"/></a> 浙公网安备33018302001367号
        <a href="https://beian.miit.gov.cn/">浙ICP备12046102号</a>
    </div>
    <div v-if="showtext" class="bounced">
      <div class="box">
        <span>
          {{ caseShows[tabsIndex-1].body }}
          <br />
          {{ caseShows[tabsIndex-1].bodys }}
        </span>
      </div>
      <div @click="showtext = false" class="mask"></div>
    </div>
  </div>
</template>

<script>
import { caseShows } from "../../publicData/laji";
export default {
  data() {
    return {
      tabsIndex: 1,
      caseShows: [],
      showtext: false,
    };
  },
  created() {
    this.$router.afterEach((to, from, next) => {
      window.scrollTo(0, 0);
    });
    this.tabsIndex = this.$route.query.index ? this.$route.query.index : 1;
    this.caseShows = caseShows;
  },
  mounted() {},
  methods: {
    showText() {
      //移动端展示更多文本
      this.showtext = true;
    },
    handleIndex(index) {
      this.tabsIndex = index;
    },
    tableIndex(left) {
      if (left) {
        if (this.tabsIndex != 1) {
          this.tabsIndex--;
        } else {
          this.tabsIndex = 6;
        }
      } else {
        if (this.tabsIndex != 6) {
          this.tabsIndex++;
        } else {
          this.tabsIndex = 1;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 1100px) {
  .box_title {
    margin-top: 110px;
    background: white;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 1920px;
    height: 440px;
    h6 {
      background: url("https://prod.oss.netopstec.com/net-box_title-bg3.png")
        no-repeat center center;
      width: 100%;
      height: 100%;
      line-height: 440px;
      align-items: center;
      text-align: center;
      position: absolute;
      top: 0;
      opacity: 0;
      font-size: 60px;
      font-weight: 500;
      span {
        color: #982037;
      }
      animation: introduce_title 1s linear 1;
      animation-fill-mode: forwards;
    }
    p {
      position: absolute;
      top: 0;
      margin-top: 306px;
      font-size: 20px;
      font-weight: 400;
      color: #6e6d6d;
    }
  }
  .tabs {
    display: flex;
    padding: 0 20px;
    justify-content: space-around;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 25px;
        font-weight: 400;
        color: #666666;
        line-height: 69px;
      }
      i {
        width: 69px;
        height: 5px;
        background: #c71538;
      }
    }
  }
  .body {
    margin-top: -1px;
    position: relative;
    height: 2618px;
    background: url("https://prod.oss.netopstec.com/net-caseShows-bg.png")
      no-repeat top center;
    background-size: 100% 100%;
    .top {
      width: 100%;
      display: flex;
      position: absolute;
      top: 166px;
      align-items: center;
      justify-content: center;

      .head {
        cursor: pointer;
        width: 1518px;
        height: 519px;
      }
      .jt1 {
        cursor: pointer;
        width: 49px;
        height: 71px;
        margin-right: 100px;
      }
      .jt2 {
        cursor: pointer;
        width: 49px;
        height: 71px;
        margin-left: 100px;
      }
    }
    .main {
      width: 100%;
      position: absolute;
      top: 765px;
      display: flex;
      justify-content: center;

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 760px;
        }
        .text {
          padding: 80px;
          width: 600px;
          background: rgba(53, 5, 17, 0.8);
          p {
            color: #ffffff;
            font-size: 30px;
          }
          span {
            display: block;
            margin-top: 40px;
            font-size: 18px;
            color: #ffffff;
            line-height: 45px;
          }
          a {
            display: none;
          }
        }
      }
      .right {
        margin-top: 125px;
        .text {
          padding: 80px;
          width: 600px;
          // height: 358px;
          background: #fcfcfc;
          color: #666666;
          p {
            font-size: 30px;
            margin-bottom: 20px;
          }
          li {
            font-size: 18px;
            margin-top: 25px;
            display: flex;
            span {
              flex-shrink: 0;
              width: 11px;
              height: 11px;
              background: #da2b4d;
              border-radius: 50%;
              margin-top: 8px;
              margin-right: 15px;
            }
          }
        }
        img {
          width: 760px;
        }
      }
    }
  }
  .footer {
    width: 1920px;
    height: 175px;
    background: url("https://prod.oss.netopstec.com/net-about-foot-8-8.png")
      no-repeat top center;
    padding-top: 100px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    line-height: 35px;
    p {
      width: 100%;
    }
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}
@media screen and (max-width: 1100px) {
  .box_title {
    margin-top: 101px;
    background: white;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 750px;
    height: 362px;
    background: url("https://prod.oss.netopstec.com/box_title-bg-h5.png")
      no-repeat center center;
    background-size: 100% 100%;
    h6 {
      background: url("https://prod.oss.netopstec.com/net-box_title-bg3.png")
        no-repeat center center;
      background-size: 100% 100%;
      width: 350px;
      height: 83px;
      line-height: 83px;
      align-items: center;
      text-align: center;
      position: absolute;
      top: 126px;
      opacity: 0;
      font-size: 40px;
      font-weight: 500;
      color: #fff;
      span {
        color: #982037;
      }
      animation: introduce_title 1s linear 1;
      animation-fill-mode: forwards;
    }
    p {
      position: absolute;
      top: 0;
      margin-top: 306px;
      font-size: 20px;
      font-weight: 400;
      color: #6e6d6d;
    }
  }
  .tabs {
    width: 750px;
    position: absolute;
    top: 286px;
    display: flex;
    // padding: 0 20px;
    justify-content: space-around;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 25px;
        font-weight: 400;
        color: white;
        line-height: 69px;
      }
      i {
        width: 69px;
        height: 5px;
        background: #c71538;
      }
    }
  }
  .body {
    width: 750px;
    margin-top: -1px;
    position: relative;
    height: 1660px;
    background: url("https://prod.oss.netopstec.com/net-caseShows-bg-h5.png")
      no-repeat top center;
    background-size: 100% 100%;
    .top {
      width: 100%;
      display: flex;
      position: absolute;
      top: 120px;
      align-items: center;
      justify-content: center;
      .head {
        cursor: pointer;
        width: 650px;
        height: 222px;
      }
      .jt1 {
        cursor: pointer;
        width: 28px;
        height: 38px;
        margin-right: 19px;
      }
      .jt2 {
        cursor: pointer;
        width: 28px;
        height: 38px;
        margin-left: 19px;
      }
    }
    .main {
      width: 100%;
      position: absolute;
      top: 402px;
      display: flex;
      justify-content: center;

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 325px;
        }
        .text {
          padding: 30px;
          // height: 450px;
          width: 265px;
          overflow: hidden;
          background: rgba(53, 5, 17, 0.8);
          p {
            color: #ffffff;
            font-size: 28px;
          }
          span {
            display: block;
            -webkit-line-clamp: 8;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 40px;
            font-size: 18px;
            color: #ffffff;
            line-height: 45px;
          }
          a {
            text-decoration: underline;
            line-height: 45px;
            font-size: 18px;
            display: block;
            margin: 0 auto;
            text-align: center;
            color: #ffffff;
          }
        }
      }
      .right {
        margin-top: 125px;
        .text {
          padding: 30px;
          width: 265px;
          background: #fcfcfc;
          color: #666666;
          p {
            font-size: 22px;
            margin-bottom: 20px;
          }
          li {
            font-size: 18px;
            margin-top: 25px;
            display: flex;
            span {
              flex-shrink: 0;
              width: 11px;
              height: 11px;
              background: #da2b4d;
              border-radius: 50%;
              margin-top: 8px;
              margin-right: 15px;
            }
          }
        }
        img {
          width: 325px;
        }
      }
    }
  }
  .footer {
    width: 750px;
    height: 216px;
    background: url("https://prod.oss.netopstec.com/net-about-foot-8-8.png")
      no-repeat top center;
    background-size: 100% 100%;
    padding-top: 100px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    line-height: 35px;
    p {
      width: 100%;
    }
    a {
      color: #ffffff;
      text-decoration: none;
    }
  }
  .bounced {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: olivedrab;
    .mask {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.6;
    }
    .box {
      padding: 5%;
      color: white;
      position: absolute;
      z-index: 2;
      background-color: rgba(53, 5, 17, 1);
      width: 70%;
      top: 20%;
      left: 10%;
      height: 60%;
      overflow-y: auto;
      span{
        font-size: 30px;
        color: #ffffff;
        line-height: 45px;
      }
    }
  }
}
.case_shows {
  position: relative;
}

.body_ini {
  position: relative;
  .main {
    .left {
      img {
        opacity: 0;
        animation: left_top 1s 0.3s linear 1;
        animation-fill-mode: forwards;
      }
      .text {
        opacity: 0;
        animation: left_bootom 1s 0.3s linear 1;
        animation-fill-mode: forwards;
      }
    }
    .right {
      .text {
        opacity: 0;
        animation: right_top 1s 0.3s linear 1;
        animation-fill-mode: forwards;
      }
      img {
        opacity: 0;
        animation: right_bootom 1s 0.3s linear 1;
        animation-fill-mode: forwards;
      }
    }
  }
}

@keyframes left_top {
  0% {
    transform: translate(-100px, -100px);
    opacity: 0;
  }
  50% {
    transform: translate(-100px, -100px);

    opacity: 0;
  }
  100% {
    transform: translate(0, 0);

    opacity: 1;
  }
}
@keyframes left_bootom {
  0% {
    transform: translate(-100px, 100px);
    opacity: 0;
  }
  50% {
    transform: translate(-100px, 100px);

    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
@keyframes right_bootom {
  0% {
    transform: translate(100px, 100px);
    opacity: 0;
  }
  50% {
    transform: translate(100px, 100px);

    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes right_top {
  0% {
    transform: translate(100px, -100px);
    opacity: 0;
  }
  50% {
    transform: translate(100px, -100px);

    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes introduce_title {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
</style>